import {ApiBase} from '@jetCommon/api/base.js';

export default class ClockingApi extends ApiBase {
    static resourceName = 'daily_clockings';

    nextClocking(companyId) {
        return this.apiGet('next_clocking/', {params: {company: companyId}});
    }

    addClocking(companyId, kind) {
        return this.apiPost('add_clocking/', {company: companyId, kind});
    }

    today(companyId) {
        return this.apiGet('today/', {params: {company: companyId}});
    }

    updateNotes(companyId, note) {
        return this.apiPatch('today/', {company: companyId, note});
    }

    deleteLastClocking(companyId) {
        return this.apiDelete('last_clocking/', {params: {company: companyId}});
    }

    yearMonthChoices(companyId) {
        return this.apiGet('year_month_choices/', {params: {company: companyId}});
    }
}
